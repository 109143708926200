import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { globalState } from '../../../shared/foreground/models';
import {
  toggleReadwiseEmailPreferences,
} from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import { DisplayTheme } from '../../../shared/types';
import HelpDropdown from './Dropdown/HelpDropdown';
import styles from './EmailPreferencesPage.module.css';
import StandardPage from './StandardPage';
import standardPageStyles from './StandardPage.module.css';
import Switch from './Switch';


export const EmailPreferencesPage = () => {
  const currentTheme = globalState(useCallback((state) => state.client.theme, []));
  const isDarkMode = currentTheme === DisplayTheme.Dark;

  return (
    <StandardPage>
      <div
        className={`${standardPageStyles.standardPageWrapper} ${styles.emailPreferencesWrapper} ${isDarkMode ? styles.isDarkMode : ''}`}>
        <div className={standardPageStyles.header}>
          <h1 className={standardPageStyles.title}>Account settings</h1>
        </div>

        <div className={`${standardPageStyles.contentMainWrapper} ${styles.contentMainWrapper} has-visible-scrollbar`}>
          <div className={standardPageStyles.contentWrapper}>
            <aside>
              <Link to="/profile">
                Profile
              </Link>
              <Link to="/preferences">
                Preferences
              </Link>
              <Link to="/integrations">
                Integrations
              </Link>
              <Link to="/product-emails" className={standardPageStyles.isActive}>
                Product Emails
              </Link>
              <br />
              <Link to="/import">
                Import Documents
              </Link>
            </aside>

            <div className={styles.content}>
              <h1 className={styles.mainHeader}>Product Emails</h1>

              <div className={styles.list}>
                <DailySummary />
              </div>

              <div className={styles.list}>
                <WisereadsNewsletter />
              </div>

              <div className={styles.list}>
                <PublicBetaUpdate />
              </div>

              <div className={styles.list}>
                <DailyReview />
              </div>

              <RecipientEmailAddress />

              <div className={styles.helpDropdownWrapper}>
                <HelpDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardPage>
  );
};

const RecipientEmailAddress = () => {
  const profile = globalState(useCallback((state) => state.client?.profile, []));
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email = '' } = profile ?? {};

  return (
    <section className={styles.section}>
      <div>
        Email notifications on this page are sent to <strong>{email}</strong>
      </div>
    </section>
  );
};

const ListItem = ({ title, subtitle, isSmall, children }: {
  title: string;
  subtitle: string | React.ReactNode;
  isSmall?: boolean;
  children: React.ReactNode;
}) => {
  return <div className={`${styles.listItem} ${isSmall ? styles.isSmall : ''}`}>
    <div className={styles.left}>
      <div className={styles.textWrapper}>
        <div className={styles.title}>
          <h1>{title}</h1>
        </div>
        <div className={styles.subtitle}>
          {subtitle}
        </div>
      </div>
    </div>

    <div className={styles.right}>
      {children}
    </div>
  </div>;
};

const DailySummary = () => {
  const dailySummary = globalState(useCallback((state) => !state.client.profile?.email_preferences?.unsubbed_from_daily_summary_emails, []));

  return (
    <ListItem
      title="Summaries Email"
      subtitle="Summaries of each document you save to your Reader Library during the previous 24 hours."
    >
      <Switch
        rootProps={{
          checked: dailySummary,
          onCheckedChange: () => toggleReadwiseEmailPreferences({
            stateUpdateOptions: { userInteraction: 'click' },
            emailPreferenceKey: 'unsubbed_from_daily_summary_emails',
            prettyNameForToast: 'Summaries Email',
          }),
        }} />
    </ListItem>
  );
};

const WisereadsNewsletter = () => {
  const wisereadsNewsletter = globalState(useCallback((state) => !state.client.profile?.email_preferences?.unsubbed_from_wisereads_emails, []));

  return (
    <ListItem
      title="Wisereads Newsletter"
      subtitle="Weekly roundup of the most highlighted content, exclusive ebooks, RSS feeds, and more."
    >
      <Switch
        rootProps={{
          checked: wisereadsNewsletter,
          onCheckedChange: () => toggleReadwiseEmailPreferences({
            stateUpdateOptions: { userInteraction: 'click' },
            emailPreferenceKey: 'unsubbed_from_wisereads_emails',
            prettyNameForToast: 'Wisereads Newsletter',
          }),
        }} />
    </ListItem>
  );
};

const PublicBetaUpdate = () => {
  const publicBetaUpdate = globalState(useCallback((state) => !state.client.profile?.email_preferences?.unsubbed_from_feedback_emails, []));

  return (
    <ListItem
      title="Public Beta Update"
      subtitle="Overview of new Reader features and improvements."
    >
      <Switch
        rootProps={{
          checked: publicBetaUpdate,
          onCheckedChange: () => toggleReadwiseEmailPreferences({
            stateUpdateOptions: { userInteraction: 'click' },
            emailPreferenceKey: 'unsubbed_from_feedback_emails',
            prettyNameForToast: 'Public Beta Update',
          }),
        }} />
    </ListItem>
  );
};

const DailyReview = () => {
  return (
    <ListItem
      title="Daily Review"
      subtitle="You can modify your email settings for the Daily Review inside of Readwise."
    >
      <a href="https://readwise.io/configure" target="_blank" rel="noreferrer" className={styles.dailyReviewSettingsLink}>Modify</a>
    </ListItem>
  );
};
